@font-face {
  font-family: 'Scto Grotesk A Light';
  src: url('../fonts/Scto-Grotesk-A-Light.woff');
}

@font-face {
  font-family: 'Scto Grotesk A Regular';
  src: url('../fonts/Scto-Grotesk-A-Regular.woff');
}

@font-face {
  font-family: 'Scto Grotesk A Bold';
  src: url('../fonts/Scto-Grotesk-A-Bold.woff');
}

@font-face {
  font-family: 'Scto Grotesk A Regular';
  font-weight: 700;
  src: url('../fonts/Scto-Grotesk-A-Bold.woff');
}

@font-face {
  font-family: 'Scto Grotesk A Light';
  font-weight: 700;
  src: url('../fonts/Scto-Grotesk-A-Bold.woff');
}

:root {
  /* https://www.figma.com/file/Je1CiMJkTMNxftGCkbtPzR/Reaktor-Tools-Design-System */
  /* root level definitions */
  --primary-element: #151515;
  --ui-background-01: #fff;
  --ui-background-02: #f9f9f9;
  --ui-01: #f4f4f6;
  --ui-02: #fff;
  --ui-03: #d3d9df;
  --ui-04: #848f97;
  --ui-05: #566570;

  --text-01: #151515;
  --text-02: #566570;
  --text-03: #848f97;
  --text-04: #fff;

  --ui-interactive-01: #0a5af5;
  --ui-interactive-02: #366af0;
  --ui-interactive-03: #034cda;
  --ui-interactive-04: #0138a3;
  --ui-interactive-05: #0040bc;

  --link-01: #0a5af5;

  --icon-01: #566570;
  --icon-02: #034cda;
  --icon-03: #fff;

  --ui-support-01: #0b8351;
  --ui-support-02: #e12a02;
  --ui-support-03: #fed342;

  --blue-P400: #011ce4;

  /* derivative definitions based on above: */

  --color-primary-noninteractive: var(--primary-element);
  --color-primary-interactive: var(--ui-interactive-01);
  --color-secondary-interactive: var(--ui-interactive-02);

  --color-primary-page-background: var(--ui-background-01);
  --color-secondary-page-background: var(--ui-01);

  --color-primary-container-background: var(--ui-01);
  --color-secondary-container-background: var(--ui-02);
  --color-low-contrast-background: #f6f8fa; /* found in hours desktop figma spec */

  --color-border-low-contrast: var(--ui-03);
  --color-border-medium-contrast: var(--ui-04);
  --color-border-high-contrast: var(--ui-05);
  --color-border-focused: var(--ui-interactive-05);
  --color-underline-focused: var(--ui-interactive-05);

  --color-primary-body-text: var(--text-01);
  --color-primary-hover-text: var(--ui-interactive-03);
  --color-secondary-body-text: var(--text-02);
  --color-secondary-hover-text: var(--text-01);

  --color-input-label: var(--text-02);
  --color-help-text: var(--text-02);
  --color-placeholder-text: var(--text-03);

  --color-text-on-interactive-fill-colors: var(--text-04);

  --color-primary-button: var(--ui-interactive-01);
  --color-secondary-button: var(--ui-interactive-02);
  --color-text-button: var(--link-01);
  --color-radiobutton-background: var(--ui-02);

  --color-active-state: var(--ui-interactive-04);
  --color-selected-elements: var(--ui-interactive-04);

  --color-primary-link: var(--link-01);

  --color-primary-icon: var(--icon-01);
  --color-interactive-icon-hover: var(--icon-02);
  --color-icon-on-colored-background: var(--icon-03);

  --color-success: var(--ui-support-01);
  --color-error: var(--ui-support-02);
  --color-error-low-contrast: #fff2f2; /* not in the palette, but we had no low-contrast error color */
  --color-waiting: var(--ui-support-03);

  /* older variables not converted to design system conformance yet: */

  --pitch-black: #151515;
  --dark-grey: #566570;
  --grey-80: var(--dark-grey);
  --grey-90: #3a4247;
  --medium-grey: #848f97;
  --light-grey-bg: #f5f8fc;
  --evening-blue: #1a6b99;
  --night-sky-blue: #02263a;
  --header-height: 60px;
  --admin-header-height: 60px;
  --entry-height: 27px;

  --vacation-circle-fill: #33ab78;
  --error-circle-fill: var(--color-error);

  --app-sidebar-bg-color: var(--ui-01);
  --app-sidebar-border-color: var(--ui-03);

  --app-sidebar-width: 368px;

  --tooltip-background: #00263A;

  /* Font variables */
  --font-regular: 'Scto Grotesk A Regular', sans-serif;
  --font-light: 'Scto Grotesk A Regular', sans-serif;
  --font-medium: 'Scto Grotesk A Bold', sans-serif;

  /* New colors from the 'design tokens' addition from Figma. Remove when design tokens are applied to hours-client */
  --color-accent-default: #e6e9fe;
  --color-text-primary: #262626;
  --color-text-secondary: #595959;
  --color-bg-default: #ffffff;
  --color-bg-muted: #f2f2f2;
  --color-k600: #4dfe91;
  --color-k800: #b3ffd0;
  --color-p300: #0116b2;
  --color-z500: #8c8c8c;
  --color-z700: #bfbfbf;
  --color-support-error: #e42001;
  --color-a900: #ffe9e6;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-color: var(--color-primary-page-background);
  font-family: var(--font-light);
  font-size: 13px;
  color: var(--color-primary-noninteractive);

  /* Makes the text look better on Chrome and Safari,
     and also makes <strong> and bolded parts much more prominent
  */
  -webkit-font-smoothing: antialiased;
}

label[for] {
  cursor: pointer;
}

a {
  color: var(--color-primary-link);
}

button {
  color: var(--color-primary-button);
}
